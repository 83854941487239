<template>
  <div>
    <!-- <v-row justify="center">
      <v-col cols="12" lg="8">
        <v-card>
          <v-app-bar v-if="role" flat color="transparent">
            <v-toolbar-title
              >{{ role.name }} {{ role.remarks }} 權限設定</v-toolbar-title
            >
            <v-spacer></v-spacer>
            <v-btn color="error" @click="deleteRole()" class="mr-2">
              刪除
            </v-btn>
            <v-btn color="primary" @click="save()"> 儲存 </v-btn>
          </v-app-bar>
          <v-card-text>
            <v-row>
              <v-col
                class="overflow-hidden"
                cols="12"
                sm="6"
                lg="4"
                v-for="permission in permissions"
                :key="permission.id"
              >
                <v-checkbox
                  v-model="selectedPermissions"
                  :label="`${permission.remarks} ${permission.name}`"
                  :value="permission.id"
                  dense
                  hide-details
                  class="mt-0"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row> -->

    <div class="text-h6 font-weight-bold mb-2 accent--text">
      {{ roleName }}列表
    </div>
    <v-data-table
      :headers="headers"
      :items="accounts"
      class="elevation-1"
      hide-default-footer
      :items-per-page="-1"
    ></v-data-table>

    <div class="text-h6 font-weight-bold mt-10 mb-2 accent--text">
      {{ roleName }}權限
    </div>
    <v-card class="pa-4">
      <v-chip class="ma-2" color="" v-for="auth in authorities">{{
        auth
      }}</v-chip>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Roles from '@/api/Roles'
import Account from '@/api/Account'

export default {
  mixins: [],
  components: {},
  props: {
    roleId: {
      type: String,
      required: true
    }
  },
  data: function () {
    return {
      permissions: [],
      selectedPermissions: [],
      allAccounts: [],
      headers: [
        { text: '信箱', value: 'email' },
        { text: '名稱', value: 'name' },
        { text: '建立時間', value: 'createdDate' },
        { text: '更新時間', value: 'updatedDate' }
      ]
    }
  },
  computed: {
    ...mapGetters({
      schoolRoles: 'role/schoolRolesOption'
    }),
    role() {
      if (!this.schoolRoles) {
        return null
      }
      return this.schoolRoles.find((role) => role.id === this.roleId)
    },
    saveParams() {
      return {
        name: this.role.name,
        remarks: this.role.remarks,
        permissions: this.selectedPermissions
      }
    },
    accounts() {
      return this.allAccounts.filter((account) => {
        return account.roles?.find((role) => role.name === this.roleId)
      })
    },
    authorities() {
      return this.accounts[0]?.authority
    },
    roleName() {
      return this.schoolRoles?.find((role) => role.name === this.roleId).remarks
    }
  },
  watch: {
    role(val) {
      this.selectedPermissions = val?.permission.map(
        (permission) => permission.id
      )
    }
  },
  created: function () {},
  mounted: function () {
    this.getAccounts()
    // this.getPermissions()
    if (!this.schoolRoles) {
      this.getRoles()
    }
    if (this.role) {
      this.selectedPermissions = this.role.permission.map(
        (permission) => permission.id
      )
    }
  },
  destroyed() {},
  methods: {
    ...mapActions({
      getRoles: 'role/getSchoolRolesOption'
    }),
    getAccounts() {
      Account.list()
        .then((resp) => {
          this.allAccounts = resp.data
        })
        .catch((error) => {
          console.log(error.response)
        })
    },
    save() {
      Roles.update(this.roleId, this.saveParams)
        .then((resp) => {
          this.getRoles()
        })
        .catch(() => {
          alert('更新失敗')
        })
    },
    deleteRole() {
      Roles.delete(this.roleId)
        .then(() => {
          window.history.go(-1)
        })
        .catch(() => {
          alert('刪除失敗')
        })
    }
  }
}
</script>

<style></style>
